import { useState, useEffect } from "react"
import "./index.css"
import { Link } from "react-router-dom"
import Modal from "react-modal"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    borderRadius: "8px",
    border: "none"
  }
}

// const Popup = () => {
//     const
//     <>
//         <Modal
//             isOpen={modalIsOpen}
//             onRequestClose={closeModal}
//             style={customStyles}
//           >
//         <div className='flex bg-white rounded-md'>
//             <h4>This feature is coming soon.</h4>
//         </div>
//     </Modal>
//     </>

// }

export const Button = ({
  text,
  icon,
  url,
  backgroundColor,
  textColor,
  comingSoon,
  modal
}) => {
  const [isShown, setIsShown] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
      >
        <div className="flex bg-white p-12">
          <h4 className="text-xl">This feature is coming soon.</h4>
        </div>
      </Modal>
      <Link to={url}>
        <button
          className={`${backgroundColor} text-${textColor} home-button flex w-[274px] items-center pl-8`}
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          <img className="mr-4" src={icon} alt="" />
          {isShown && comingSoon ? "Coming Soon" : text}
        </button>
      </Link>
    </>
  )
}

export const DashboardButton = ({
  text,
  backgroundColor,
  url,
  textColor,
  onClick,
  disabled,
  id
}) => {
  return (
    <Link to={url}>
      <button
        id={id}
        className={`${backgroundColor} text-${textColor} dashboard-button flex justify-center`}
        onClick={onClick}
        style={{ opacity: disabled ? 0.5 : 1, color: textColor }}
        disabled={disabled}
      >
        {text}
      </button>
    </Link>
  )
}
