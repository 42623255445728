import { useEffect, useState } from "react"
import Chart from "chart.js/auto"
import DashboardFrame from "../dashboard"
import "./index.css"
import { DashboardButton } from "../../components/Button"
import { Store } from "../../StoreContext"
import { Line } from "react-chartjs-2"
import {
  VictoryLine,
  VictoryChart,
  VictoryTheme,
  VictoryLabel,
  VictoryAxis,
  VictoryScatter, VictoryContainer
} from "victory"

const Body = () => {
  const [dataGraph, setDataGraph] = useState([])
  const [metric, setMetric] = useState(false)
  const [volume, setVolume] = useState(false)
  const [loadingToolName, setLoadingToolName] = useState("")
  const [labels, setLabels] = useState([])
  const [dataSets, setDataSets] = useState([])
  const {
    estRoundTripTravel,
    haulagePerhour,
    loadingToolOptions,
    haulingTool,
    parameters_unit,
    loadingTool,
    trucks,
    graphData
  } = Store()
  const truck = [...graphData].reverse()
  useEffect(() => {
    // handlemaxLdRttt()
    handleLoadingName()
    if (loadingToolName === "" && haulingTool === "") {
      window.location = "/fleet-definition"
    }
  }, [])

  useEffect(() => {
    if (parameters_unit === "metric") {
      setMetric(true)
    } else if (parameters_unit === "us") {
      setMetric(false)
    }
  }, [parameters_unit])


  // func for finding name of loadingTool againist it's ID
  const handleLoadingName = () => {
    const name = loadingToolOptions.find(item => {
      return item?.value === loadingTool
    })?.label
    setLoadingToolName(name)
  }

  // func for metric checkbox
  const HandleMetricChange = e => {
    if (e.target.checked === true) {
      setMetric(true)
    } else {
      setMetric(false)
    }
  }
  // func for volume checkbox
  const HandleVolumeChange = e => {
    if (e.target.checked === true) {
      setVolume(true)
    } else {
      setVolume(false)
    }
  }

  useEffect(() => {
    getGraphValues()
  }, [metric, volume])



  const getGraphValues = () => {
    const truckPoints = graphData.map(truck => {
      const graph_data = truck?.points?.map(point => {
        let dy =
          metric && !volume ?
            point?.y?.metric :
            !metric && volume ?
              point?.y?.is_volume?.us :
              !metric && !volume ?
                point?.y?.us :
                metric && volume &&
                point?.y?.is_volume?.metric

        return { x: point.x, y: dy }
      })

      return {
        max_ld_rttt: truck?.rttt,
        graph_data: graph_data,
        item: truck?.item
      }
    })

    setDataGraph(truckPoints)
  }


  return (
    <div className="graph">
      <div className="graph-checkboxes">
        <div className="graph-checkboxes-container">
          <div>
            <input
              type="checkbox"
              checked={metric}
              onChange={HandleMetricChange}
              className="checkbox"
            />
            <label htmlFor="">Metric</label>
          </div>
          <div>
            <input
              type="checkbox"
              checked={volume}
              onChange={HandleVolumeChange}
              className="checkbox"
            />
            <label htmlFor="">Volume</label>
          </div>
        </div>
      </div>
      <div className="chart-container">
        <div className="chart-header">
          <h2>
            {loadingToolName} x {haulingTool}
          </h2>
          <ul>
            {truck?.map((item, index) => {
              return (
                <li>
                  <div
                    className="bullet"
                    style={{
                      backgroundColor: index === 0 ? "#FF8C00"
                        : index === 1 ? "#0000FF"
                          : index === 2 && "#008000"
                    }}
                  />
                  {item.item + " trucks"}
                </li>
              )
            })}
          </ul>
        </div>


        <div style={{ paddingBottom: "1rem", height: "350px", }}>
          <VictoryChart
            theme={VictoryTheme.material}
            minDomain={{ y: 0, x: 0 }}
            domainPadding={{ x: 9, y: 9 }}
            width={950}
            containerComponent={
              <VictoryContainer responsive={true} />}
          // height={400}
          >

            {metric && !volume
              ?
              <VictoryLabel
                text={`LD = ${parseFloat(Math.round(haulagePerhour?.metric || 0)).toLocaleString("us")} tonnes/hr`
                }
                x={60}
                y={40}
                style={[{ fontSize: 16 }]} />

              : !metric && !volume
                ?
                <VictoryLabel
                  text={
                    `LD = ${parseFloat(Math.round(haulagePerhour?.us || 0)).toLocaleString("us")} tons/hr`
                  }
                  x={60}
                  y={40}
                  style={[{ fontSize: 16 }]} />


                : !metric && volume
                  ?
                  <VictoryLabel
                    text={
                      `LD = ${parseFloat(Math.round(haulagePerhour?.is_volume?.us || 0)).toLocaleString("us")} bcy/hr`
                    }
                    x={60}
                    y={40}
                    style={[{ fontSize: 16 }]} />


                  : metric &&
                  volume &&
                  <VictoryLabel
                    text={
                      `LD = ${parseFloat(Math.round(haulagePerhour?.is_volume?.metric || 0)).toLocaleString("us")} bcm/hr`
                    }
                    x={60}
                    y={40}
                    style={[{ fontSize: 16 }]} />

            }
            <VictoryLine
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 }
              }}
              data={dataGraph[0]?.graph_data}
              style={{
                data: { stroke: "#008000", fontSize: 40 },
              }}
            />

            <VictoryScatter
              size={5}
              domain={[-10, 10]}
              data={[{ x: dataGraph[0]?.max_ld_rttt, y: 0 }]}
              labels={() => ["↑"]}
              labelComponent={
                <VictoryLabel
                  backgroundStyle={[{ fill: "#fff" }]}
                  backgroundPadding={5}
                  style={[{ fill: "#008000", fontSize: 40 }]}
                />
              }
            />

            {dataGraph.length > 1 && (
              <VictoryLine
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 }
                }}
                data={dataGraph[1].graph_data}
                style={{
                  data: { stroke: "#0000FF" }
                }}
              />
            )}

            {dataGraph.length > 1 && (
              <VictoryScatter
                size={5}
                domain={[-10, 10]}
                data={[{ x: dataGraph[1].max_ld_rttt, y: 0 }]}
                labels={() => ["↑"]}
                labelComponent={
                  <VictoryLabel
                    backgroundStyle={[{ fill: "#fff" }]}
                    backgroundPadding={5}
                    style={[{ fill: "#0000FF", fontSize: 40 }]}
                  />
                }
              />
            )}

            {dataGraph.length > 2 && (
              <VictoryLine
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 }
                }}
                data={dataGraph[2].graph_data}
                style={{
                  data: { stroke: "#FF8C00" }
                }}
              />
            )}

            {dataGraph.length > 2 && (
              <VictoryScatter
                size={5}

                domain={[-10, 10]}
                data={[{ x: dataGraph[2].max_ld_rttt, y: 0 }]}
                labels={() => ["↑"]}
                labelComponent={
                  <VictoryLabel
                    backgroundStyle={[{ fill: "#fff" }]}
                    backgroundPadding={5}
                    style={[{ fill: "#FF8C00", fontSize: 40 }]}
                  />
                }
              />
            )}

            <VictoryAxis
              label="Round Trip Travel Time (minutes)"
              style={{ axisLabel: { padding: 30, fontSize: 16 } }}
            />
            <VictoryAxis
              dependentAxis
              label={`Fleet productivity (${metric && !volume
                ? "tonnes/hr"
                : !metric && !volume
                  ? "tons/hr"
                  : !metric && volume
                    ? "bcy/hr"
                    : "bcm/hr"
                })`}
              fixLabelOverlap
              style={{
                axisLabel: { padding: 30, fontSize: 16 },
                tickLabels: { angle: 270 }
              }}
            />
          </VictoryChart>
        </div>
      </div>
      <div className="btn_div">
        <div style={{ cursor: "pointer" }}>
          <DashboardButton
            text="Save Results"
            backgroundColor="blue"
            textColor="white"
            url=""
          />
        </div>
      </div>
    </div>
  )
}

const Graph = () => {
  return (
    <DashboardFrame
      title="Graphical View"
      body={<Body />}
      backButtonURL="/result"
    />
  )
}

export default Graph
